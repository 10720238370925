<template>
  <main class="movies">
    <vue-headful v-bind:title="dynamicTitle" />
  </main>
</template>

<script>
/* eslint max-len: 0 */
/* eslint prefer-destructuring: 0 */
import vueHeadful from 'vue-headful';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import APP_CONFIG from '@/appConfig';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      firstMovie: {},
      movieDetail: {},
      dynamicTitle: '',
    };
  },
  async mounted() {
    const bfAPI = new BifrostAPI();
    const movies = await bfAPI.getMovieListNewest(0, 1);
    const newestMovies = movies.Rows;
    this.firstMovie = newestMovies[0];
    const movieId = this.firstMovie.MovieID;
    this.movieDetail = await bfAPI.getMovieDetail(movieId);

    // set browser title
    const actors = (this.locale === 'en')
      ? this.movieDetail.ActressesEn.join()
      : this.movieDetail.ActressesJa.join();
    const title = this.locale === 'en' ? '' : this.movieDetail.Title;
    this.dynamicTitle = `${actors} ${title} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // now redirect to movie detail
    this.$router.push({ path: `/movies/${movieId}/` });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
</style>
